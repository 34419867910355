export const GET_MATCHES_API_PATH = "matches";
export const GET_MY_MATCHES_API_PATH = "my-matches";
export const GET_MATCH_DETAIL_API_PATH = "matches/{}";
export const GET_SPECIAL_CONTEST_API_PATH ="/app/leaderboards?gameType=fantasy"

export const JOIN_CONTEST_API_PATH = "contests/{}/join";
export const GET_CONTEST_DETAIL_API_PATH = "contests/{}";
export const GET_MATCH_CONTESTS_API_PATH = "contests?match_id={}";
export const GET_MY_MATCH_CONTESTS_API_PATH = "my-contests?match_id={}";
export const GET_CONTEST_LEADERBOARD_API_PATH = "contests/{}/leaderboard";
export const GET_SPECIAL_CONTEST_DETAIL_API_PATH = "/app/leaderboards/{}"
export const GET_SPECIAL_CONTEST_LEADERBOARD_API_PATH = "/app/leaderboards/{}/leaderboard"

export const CREATE_TEAM_API_PATH = "teams";
export const GET_MATCH_TEAMS_API_PATH = "teams?match_id={}";

export const PROFILE_PIC_API_PATH = "app/profilePic";
export const PROFILE_API_PATH = "app/profile";
export const SIGN_UP_USER_API_PATH = "app/signup";
export const SIGN_IN_USER_API_PATH = "app/oauth-signin";

export const USER_BALANCE_API_PATH = "app/balance";
export const INITIATE_TRANSACTION_API_PATH = "app/txns/init";

export const KYC_DETAILS_API_PATH = "app/kyc";
export const PAYMENT_METHOD_LIST_API_PATH = "app/payments";
export const WITHDRAWAL_REQUEST_API_PATH = "app/withdrawals";

export const TEAM_DETAIL_API_PATH = "teams/{}/";
