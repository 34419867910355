import { Button } from "antd-mobile";
import { useHistory } from "react-router";

import { INDEX_ROUTE } from "routes";

import "./style.scss";

const NotFoundPage = () => {
  const history = useHistory();
  return (
    <div className="not-found-page">
      <p className="text-3xl mb-12">Not found</p>
      <Button type="primary" onClick={() => history.push(INDEX_ROUTE)}>
        Go to home
      </Button>
    </div>
  );
};

export default NotFoundPage;
