import React, { Component } from 'react';
import store from 'store';
import {Redirect } from 'react-router-dom';
import isLoggedIn from '../helpers/is_logged_in';
import capitalize from '../helpers/capitalize';
import axios from 'axios';
import BuyinModal from '../BuyinModal';

import { GiConsoleController} from 'react-icons/gi';
import { GrTrophy} from 'react-icons/gr';
import { IconContext } from "react-icons";

var CBBuyin = [];

/* Table Card Item */
function Item(props) {
  const {band, buyin, onItemClick,rakepct,tblId} = props;
  const icon = `./img/${capitalize(band)}.png`;

  return (
    <div className="item" onClick={(event) => onItemClick(event, buyin,tblId)}>
      <p>
        <span>₹{buyin}</span>
          <div className="play">
            <button type="button" aria-label="addcash">Play</button>
        </div>
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <div className="line-border"></div>
      <div className="sub-item">
        <p>
          <span>
          <IconContext.Provider
            value={{ color: 'grey', size: '10px' }}>
              <div>
                <GrTrophy/>
                &nbsp;&nbsp;1st PRIZE ₹{buyin*4-((buyin*4)*(rakepct/100))}
                </div>
            </IconContext.Provider>
          </span>
          <span>
          <IconContext.Provider
            value={{ color: 'grey', size: '15px' }}>
              <div>
                <GiConsoleController/>
                &nbsp;Players 4
                </div>
            </IconContext.Provider>
          </span>
          </p>
      </div>
    </div>
  );
}

export default class Chatai extends Component {
    constructor(props) {
        super(props);

        this.state = {
          ...props.location.state,

          showBuyinModal: false,

          /* Data needed to launch the buyin */
          players: 2,
          buyIn: null,
        }
    }

    goBack = (event) => {
      var {history} = this.props;
      history.goBack();
    }

    handleLogout() {
        var {history} = this.props;

        store.remove('loggedIn');
        history.push('/login');
    }

    fetchTables(gameType) {
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';
      var self = this;
      const {tables} = {...this.state};
      const tbls = tables;

      axios.get(apiBaseUrl + '/tables?gameType=' + gameType).then(
          function(response) {
              console.info(response.data);
              // ludoBuyin=response.data;
              if(CBBuyin.length <=0)
              {
                for (var i=0; i < response.data.length; i++) {
                CBBuyin.push(response.data[i]);
                }
                CBBuyin.reverse();
              }

              if (response.status === 200) {
                tbls[gameType] = response.data
                self.setState({tables: tbls});
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }

    componentDidMount() {
      this.fetchTables('chatai');
    } 

    onItemClick = (event, buyin,tblId) => {
      console.log(buyin);
      console.log(tblId)
      // if (!buyin)
      //   return;

      const {players, tables} = this.state;

      var tbl = null;
      for (var i = 0; i < tables.length; ++i) {
        var table = tables[i];
        if (table.numPlayers === players &&
            table.minBuyin <= buyin && table.maxBuyin >= buyin && table.tableId === tblId) {
              console.log(table);
          tbl = table;
          break;
        }
        else{
          console.log("No table found");
        }
      }

      if (tbl) {
        console.info("table selected", table);
        this.setState({buyIn: buyin, tableId: tbl.tableId, showBuyinModal: true});
      }
    }

    onCancelBuyin = (_event) => {
      this.setState({showBuyinModal: false});
    }

    onPlayBuyin = (event) => {
      event.preventDefault();

      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/app';

      const {buyIn, tableId, gameType} = this.state;

      const payload = {
          'buyIn': buyIn,
          'tableId': tableId,
          'gameType': gameType,
          'skinId': "aio"
      };

      axios.post(apiBaseUrl +'/buyin2', payload).then(
          function(response) {
              console.info(response);
              if (response.status === 200) {
                  var url = process.env.REACT_APP_WEBD_BASE_URL + '/launch';
                  var s1  = '?wagerId=' + response.data.wagerId;
                  var s2  = '&skinId=' + process.env.REACT_APP_SKIN_ID;
                  var s3  = '&gameType=' + gameType;

                  window.location.href = url + s1 + s2 + s3;
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }
    

    toggleNumPlayers = (event, numPlayers) => {
      this.setState({players: numPlayers});
    }


    render() {
        const {balance, showBuyinModal, players, buyIn, category} = this.state;

        if (!isLoggedIn()) {
            return <Redirect to="/login"/>
        }

        console.log(CBBuyin)

        var bal = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "INR",
          }).format(balance);

        

        return (
          <div id="app-lobby-201">
            <div className="lobby201-content">
              <div className="header-container">
                <div className="header">
                  <div className="arrow-back-bg common201-bg" onClick={this.goBack}></div>
                  <div className="title">Chatai</div>
                  
                </div>
              </div>
              <div className="cards201">
              {CBBuyin.map((val,key) => (
                  <div className="card201-items club" key={key}>
                    <Item buyin={val.betValue} rakepct={val.rakePct} tblId={val.tableId} onItemClick={this.onItemClick} />
                    </div>
                ))}
              </div>
            </div>
            { showBuyinModal &&
              <BuyinModal category={category} players={players} buyin={buyIn}
                          cancelHandler={this.onCancelBuyin}
                          playHandler={this.onPlayBuyin}/>
            }
          </div>
        );
    }
}