import { useEffect, useState, Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ActivityIndicator } from "antd-mobile";

import { isEmpty } from "_dash";
import { getProfile } from "actions";
import { FIREBASE_CONFIG } from "./firebase";
import axios from "axios";

import {
  INDEX_ROUTE,
  LOGGED_IN_ROUTES,
  NON_LOGIN_ROUTES,
  USER_EDIT_PROFILE_ROUTE,
} from "./routes";

import firebase from "firebase";
import AccountHook from "hooks/userAccount";
import RouteWithSubRoutes from "components/routeWithSubRoutes";

const FantasyApp = ({ accountData, setAccountData }) => {

  const isLoggedIn = true;
  const [loading, setLoading] = useState(true);
  const [currentRoutes, setCurrentRoutes] = useState([]);

  console.log(isLoggedIn,accountData);

  // useEffect(() => {
  //   firebase.initializeApp(FIREBASE_CONFIG);
  // }, []);
  
  useEffect(() => {

    setLoading(true);

    // getProfile()
    //   .then((response) => {

      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';
      var self = this;
  
      axios.get(apiBaseUrl + '/profile').then(
          function(response) {
      
        console.log(response);
        const urlParams = new URLSearchParams(window.location.search);
        console.log(urlParams);
        setCurrentRoutes(LOGGED_IN_ROUTES);
        setAccountData(response);
        console.log(Response);
        setLoading(false);
        // if (urlParams.get("login")) {
        //   //window.location = INDEX_ROUTE;
        // }
      })
      .catch((err) => {
        setCurrentRoutes(LOGGED_IN_ROUTES);
        setLoading(false);
      });
  }, [isLoggedIn]);

  console.log(isLoggedIn);

  if (loading) {
    return <ActivityIndicator toast animating text="Loading..." />;
  }

  //console.log(LOGGED_IN_ROUTES);

  return (
    <Suspense fallback={<ActivityIndicator toast animating />}>
      <BrowserRouter>
        <Switch>
          {currentRoutes.map((route, i) => {
            return <RouteWithSubRoutes key={i} {...route} />;
          })}
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default AccountHook(FantasyApp);
