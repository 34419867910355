import React, { Component } from 'react';

function BuyinModal(props) {
    const {category, players, buyin, cancelHandler, playHandler} = props;
  
    return (
      <div id="myModal" className="modal">
        <div className="modal-content">
          <div className="modal-header">
          </div>
          <div className="modal-body">
             <div className=" popuptable">
                <table className="table">
                  <tbody>
                  <tr>
                    <td><b>Game</b></td>
                    <td align="right">{category}</td>
                  </tr>
                  <tr>
                    <td><b>Players</b></td>
                    <td align="right">{players}</td>
                  </tr>
                  <tr>
                    <td><b>Bet amount</b></td>
                    <td align="right">₹ {buyin}</td>
                  </tr>
                  </tbody>
                </table>
             </div>
             <div className="">
               <button type="button" className="btn red-btn cancel-btn"
                       onClick={(event) => cancelHandler(event)}>Cancel</button>
               <button type="button" className="btn red-btn active"
                       onClick={(event) => playHandler(event)}>Play</button>
             </div>
          </div>
        </div>
      </div>
    );
  }

export default BuyinModal;