import firebase from "firebase";

export const FIREBASE_CONFIG = {
  projectId: "proj-dangal",
  messagingSenderId: "686059545174",
  storageBucket: "proj-dangal.appspot.com",
  authDomain: "proj-dangal.firebaseapp.com",
  appId: "1:686059545174:web:fd4536b9244407c6",
  apiKey: "AIzaSyDa6MDNh8forZCkIBzxYIZHR9Xb8IDJJl0",
  databaseURL: "https://proj-dangal.firebaseio.com",
};

export const getFirebaseUiConfig = (onSignInSuccess) => {
  return {
    signInFlow: "popup",
    signInOptions: [
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        recaptchaParameters: {
          type: "image",
          size: "invisible",
          badge: "bottomleft",
        },
        defaultCountry: "BD",
      },
    ],
    immediateFederatedRedirect: false,
    queryParameterForSignInSuccessUrl: "signInSuccessUrl",
    privacyPolicyUrl: () => {
      window.location.assign("/privacy-policy");
    },
    tosUrl: () => {
      window.location.assign("/terms-conditions");
    },
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        authResult.user.getIdToken().then(function (idToken) {
          onSignInSuccess(idToken);
        });

        return false;
      },
      uiShown: () => {},
      signInFailure: (error) => {
        console.log(error);
      },
    },
  };
};
