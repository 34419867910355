import React, { Component } from 'react';
import { getCountdownTime } from "utils/countdown";
import BuyinModal from '../BuyinModal';
import Quiz from './Quiz';

function QuizContest(props) {
    const {goBack, Title, buyin, prizePool, winners,numPlaying,endAt,playHandler,showBuyinModal,onPlayBuyin,onCancelBuyin,leaderboard} = props;

    console.log(Title);
  
    return (
    <div id="app-lobby-201">
    <div className="lobby201-content">
      <div className="header-container">
        <div className="header">
          <div className="arrow-back-bg common201-bg" onClick={goBack}></div>
          <div className="title">{Title}</div>
          </div>
          <div className="cards201">
              <div>
                  <div className="card201-items club">
              <div className="item" onClick={playHandler}>
                  <p>
                  <span>Prize Pool</span>
                  <span>Entry</span>
                  <div className="play">
                      <button type="button" aria-label="addcash">Play</button>
                  </div>
                  </p>
                  <p>
                  <span>₹ {prizePool}</span>
                  <span>₹ {buyin}</span>
                  <span></span>
              </p>
              <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <div className="line-border"></div>
              <div className="sub-item">
                  <p>
                  <span>Winners {winners}</span>
                  <span>{numPlaying} Playing</span>
                  <span>
                  <p><span>Ends in</span></p>
                  <span>{getCountdownTime(endAt)}</span>
                  </span>
                  </p>
              </div>
              </div>
              </div>
              </div>
              </div>
          <div className="tabs">
            <Tabs>
                <Tab label="Winning">
                <div className="cards201">
                    <div>
                        <div className="card201-items club">
                    <div className="item">
                        <p>
                        <span>Position</span>
                        <span>Winning</span>
                        </p>
                        <div className="line-border"></div>
                        <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                        <p>
                        <span>#1</span>
                        <span>₹{150.0}</span>
                        </p>
                        <div className="line-border"></div>
                        <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                        <p>
                        <span>#1 - 5</span>
                        <span>₹{50.0}</span>
                        </p>
                        <div className="line-border"></div>
                        <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                        <p>
                        <span>#6 - 15</span>
                        <span>₹{25.0}</span>
                        </p>
                        <div className="line-border"></div>
                        <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                        <p>
                        <span>#16 - 30</span>
                        <span>₹{5.0}</span>
                        </p>
                        <div className="line-border"></div>
                        <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                        <p>
                        <span>#31 - 50</span>
                        <span>₹{1.0}</span>
                        </p>
                        <div className="line-border"></div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                        <span>Terms and Conditions Apply</span>
                        </p>
                        </div>
                        </div>
                    </div>
                 </div>
                    
                </Tab>
                <Tab label="Leaderboard">
                <div className="cards201">
                    <div>
                        <div className="card201-items club">
                      <div className="item" onClick={(event) => onItemClick(event, buyin)}>
                        <p>
                        <span>All Teams</span>
                        </p>
                        <div className="line-border"></div>
                        <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                        <p>
                          {/* {leaderboard.map((val,key) => (
                              <p>
                                <span>#1</span>
                                <span>Vasanth</span>
                                <span>₹{20.0} Points</span>
                              </p>
                          ))} */}
                        <span>#1</span>
                        <span></span>
                        <span>₹{20.0} Points</span>
                        </p>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <p>
                        <span>#1</span>
                        <span></span>
                        <span>₹{20.0} Points</span>
                        </p>
                        </div>
                        </div>
                    </div>
                 </div>
                </Tab>                
            </Tabs>
        </div>
        </div>
        <div className="quiz-button" onClick={playHandler}>
            <p>Continue Quiz</p>
            </div>
        </div>

        { showBuyinModal &&
              <BuyinModal buyin={buyin}
                          cancelHandler={onCancelBuyin}
                          playHandler={onPlayBuyin} 
                          />
              }

        </div>
);
}

export default QuizContest;

class Tabs extends React.Component{
  state ={
    activeTab: this.props.children[0].props.label
  }
  changeTab = (tab) => {

    this.setState({ activeTab: tab });
  };
  render(){
    
    let content;
    let buttons = [];
    return (
      <div>
        {React.Children.map(this.props.children, child =>{
          buttons.push(child.props.label)
          if (child.props.label === this.state.activeTab) content = child.props.children
        })}
         
        <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab}/>
        <div className="tab-content">{content}</div>
        
      </div>
    );
  }
}

const TabButtons = ({buttons, changeTab, activeTab}) =>{
   
  return(
    <div className="tab-buttons">
    {buttons.map(button =>{
       return <button key={button} className={button === activeTab? 'active': 'inactive'} onClick={()=>changeTab(button)}>{button}</button>
    })}
    </div>
  )
}

const Tab = props =>{
  return(
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}