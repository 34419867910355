import React, { Component } from 'react';
import store from 'store';
import {Redirect } from 'react-router-dom';
import isLoggedIn from '../helpers/is_logged_in';
import capitalize from '../helpers/capitalize';
import axios from 'axios';
import BuyinModal from '../BuyinModal';

import { GiConsoleController} from 'react-icons/gi';
import { GrTrophy} from 'react-icons/gr';
import { IconContext } from "react-icons";

/* Table Card Item */

var TeenPattiBuyin = [];

function Item(props) {

  const {band, buyin, numSeats, onItemClick} = props;
  const icon = `./img/${capitalize(band)}.png`;

  return (

    <div className="item" onClick={(event) => onItemClick(event, buyin, numSeats)}>
       <p>
        <span>₹{buyin}</span>
          <div className="play">
            <button type="button" aria-label="addcash">Play</button>
        </div>
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <div className="line-border"></div>
      <div className="sub-item">
          <p>
          <span>
          <IconContext.Provider
            value={{ color: 'grey', size: '10px' }}>
              <div>
                <GrTrophy/>
                </div>
            </IconContext.Provider>
          </span>
          <span>
          <IconContext.Provider
            value={{ color: 'grey', size: '15px' }}>
              <div>
                <GiConsoleController/>
                &nbsp;{numSeats}
                </div>
            </IconContext.Provider>
          </span>
          </p>
      </div>
    </div>
  );
  
}

export default class TeenPatti extends Component {
    constructor(props) {
        super(props);

        this.state = {
          ...props.location.state,

          showBuyinModal: false,
          balance: null,

          /* Data needed to launch the buyin */
          players: null,
          buyIn: null,
        }
    }

    goBack = (event) => {
      var {history} = this.props;
      history.goBack();
    }

    handleLogout() {
        var {history} = this.props;

        store.remove('loggedIn');
        history.push('/login');
    }

    fetchTables(gameType) {
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';
      var self = this;
      const {tables} = {...this.state};
      console.log(tables);
      const tbls = tables;

      axios.get(apiBaseUrl + '/tables?gameType=' + gameType).then(
          function(response) {
              console.info(response.data);
              if(TeenPattiBuyin.length <=0)
              {
                  for (var i=0; i < response.data.length; i++) {
                    TeenPattiBuyin.push(response.data[i])
                }
                TeenPattiBuyin.reverse();
                console.log(TeenPattiBuyin);
              }
              console.log(TeenPattiBuyin); 
              if (response.status === 200) {
                console.log(tbls);
                self.setState({tables: tbls});
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }


    fetchUserProfile() {
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';
      var self = this;
      const {tables} = {...this.state};
      const tbls = tables;

      axios.get(apiBaseUrl + '/balance').then(
          function(response) {
            console.log(response);
              if (response.status === 200 && Number.isFinite(response.data.balance)) {
                self.setState({balance: response.data.balance});
              } else {
                self.handleLogout();
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }

    componentDidMount() {
      this.fetchTables('teenpatti');
      this.fetchUserProfile();
    }


    onItemClick = (event, buyin, numSeats) => {
      console.log(buyin)

      // if (!buyin)
      //   return;

      const {tables} = this.state;
      console.log(buyin);
      var tbl = null;
      for (var i = 0; i < tables.length; ++i) {
        var table = tables[i];
        if (table.numPlayers == numSeats &&
            table.minBuyin <= buyin && table.maxBuyin >= buyin) {
              console.log(table);
          tbl = table;
          break;
        }
      }

      if (tbl) {
        console.info("table selected", table);
        this.setState({buyIn: buyin, tableId: tbl.tableId, players: numSeats, showBuyinModal: true});
      }
    }

    onCancelBuyin = (_event) => {
      this.setState({showBuyinModal: false});
    }

    onPlayBuyin = (event) => {
      event.preventDefault();

      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/app';

      const {buyIn, tableId, gameType} = this.state;

      const payload = {
          'buyIn': buyIn,
          'tableId': tableId,
          'gameType': gameType,
          'skinId': "aio"
      };

      axios.post(apiBaseUrl + '/buyin2', payload).then(
          function(response) {
              console.info(response);
              if (response.status === 200) {
                  // var url = process.env.REACT_APP_WEBD_BASE_URL + '/launch';
                  // var s1  = '?wagerId=' + response.data.wagerId;
                  // var s2  = '&skinId=' + process.env.REACT_APP_SKIN_ID;
                  // var s3  = '&gameType=' + gameType;


                  var url = response.data.url ;
                  var searchParams = new URLSearchParams(new URL(url).search);
                  searchParams.set("skinId", "aio");
                  var updatedURL = url.split('?')[0] + '?' + searchParams.toString();
                  console.log(updatedURL);

                  window.location.href = updatedURL;
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }

    render() {
        const {balance, showBuyinModal, players, buyIn, category} = this.state;

        if (!isLoggedIn()) {
            return <Redirect to="/login"/>
        }

        var bal = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "INR",
          }).format(balance);

          console.log(balance);

        return (
          <div id="app-lobby-201">
            <div className="lobby201-content">
              <div className="header-container">
                <div className="header">
                  <div className="arrow-back-bg common201-bg" onClick={this.goBack}></div>
                  <div className="title">TeenPatti</div>
                </div>
              </div>

              <div className="cards201">
                <div className="card201-items club">
                
                {TeenPattiBuyin.map((val,key) => (
                  <div className="card201-items club" key={key}>
                    <Item buyin={val.minBuyin} numSeats={val.numPlayers}  onItemClick={this.onItemClick} /> 
                    </div>
                ))}
                </div>
              </div>
            </div>
            { showBuyinModal &&
              <BuyinModal category={category} players={players} buyin={buyIn}
                          cancelHandler={this.onCancelBuyin}
                          playHandler={this.onPlayBuyin}/>
            }
          </div>
        );
    }
}