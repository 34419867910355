import React, { Component} from 'react';
import store from 'store';
import {Redirect } from 'react-router-dom';
import isLoggedIn from '../helpers/is_logged_in';
import capitalize from '../helpers/capitalize';
import axios from 'axios';
import QuizContest from "./QuizContestItem.js";
import { getCountdownTime } from "utils/countdown";

import { BsAlarm } from "react-icons/bs";
import { IconContext } from "react-icons";

var quizBuyin=[];
/* Table Card Item */
function Item(props) {
  
  const {band, ContestDetails,onItemClick} = props;
  const icon = `./img/${capitalize(band)}.png`;

  return (
    <div>
      <div className='item-text'>
      <p>{ContestDetails.title}</p>
      </div>
    <div className="item">
       <p>
       <span>Prize Pool</span>
       <span>Entry</span>
       <div className="play">
         <button type="button" aria-label="addcash" onClick={(event) => onItemClick(event,ContestDetails)}>Play</button>   
        </div>
       </p>
       <p>
        <span>₹ {ContestDetails.prizePool}</span>
        <span>₹ {ContestDetails.entryFee}</span>
        <span></span>
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <div className="line-border"></div>
      <div className="sub-item">
        <p>
          <span>Winners {ContestDetails.winner}</span>
          <span>{ContestDetails.numPlaying} Playing</span>
            <span>
            <p> 
            <IconContext.Provider
            value={{ color: 'grey', size: '10px' }}>
              <div>
                <BsAlarm/>
              </div>
            </IconContext.Provider>
              <span>Ends in</span></p>
          <span>{getCountdownTime(ContestDetails.details.endAt)}</span>
          </span>
        </p>
      </div> 
    </div>
    </div>
  );
}

export default class Quiz extends Component {
    constructor(props) {
        super(props);

        this.state = {
          ...props.location.state,
          showContest: false,
          showBuyinModal: false,
          /* Data needed to launch the buyin */
          players: 2,
          buyIn: null,
          name: "",
          contestTitle: null,
          prizePool:null,
          winners:null,
          nPlaying:null,
          endAt:null,
          quiztbls:[],
          myMatches:[],
          contestId:null,
          contestDetailsId:null
        }
    }

    goBack = (event) => {
      var {history} = this.props;
      history.goBack();
    }

    handleLogout() {
        var {history} = this.props;
        store.remove('loggedIn');
        history.push('/login');
    }

    goToContest=(event) => {
        var {history} = this.props;
        history.push('/QuizContest');
    }

    fetchTables() {
        var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL;
        var self = this;
        const {tables} = {...this.state};
        const tbls = tables;

        axios.get(apiBaseUrl+'/app/quiz/contests').then(
            function(response) {
              console.log(response);
              if(quizBuyin.length <=0)
                {
                    for (var i=0; i < response.data.length; i++) {
                    quizBuyin.push(response.data[i]);
                  }
                }
             
                if (response.status === 200) {
                  self.setState({quiztbls: quizBuyin});
              }
          }
        ).catch(function(error) {
          console.log(error);
        });
      }

      fetchMyMatches() {
        var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL;
        var self = this;
        const {tables} = {...this.state};
        const tbls = tables;

        axios.get(apiBaseUrl+'/app/quiz/my-contests').then(
            function(response) {
              console.log(response);
          }
      ).catch(function(error) {
          console.log(error);
      });
    }

    fetchLeaderboard()
    {
      //contest_id=82;
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL;
      axios.get(apiBaseUrl+ '/app/quiz/contests/84/leaderboard').then(
        function(response){
          console.log(response);
        }
      ).catch(function(error){
        console.log(error);
      })
    }

    componentDidMount() {
        this.fetchTables();
        //this.fetchMyMatches();
        //this.fetchLeaderboard();
      } 

    onItemClick = (event, ContestDetails ) => {

        this.setState({contestTitle: ContestDetails.title, buyIn:ContestDetails.entryFee, winners:ContestDetails.winner, endAt:ContestDetails.details.endAt, nPlaying:ContestDetails.numPlaying, prizePool:ContestDetails.prizePool, showContest: true, contestId:ContestDetails.details.contestId, contestDetailsId:ContestDetails.details.id});
      }

    goBackContest = (_event) => {
        this.setState({showContest: false});
      }

    onContestItemClick = (event) => {
        this.setState({showBuyinModal: true});
      }

    onCancelBuyin = (_event) => {
        this.setState({showBuyinModal: false});
      }

    onPlayBuyin = (event) => {
      event.preventDefault();

      this.setState({showBuyinModal: false});

      var apiBaseUrl =  process.env.REACT_APP_WEBD_BASE_URL + '/app/join2?gameType=quiz';
      const {buyIn, contestId,contestDetailsId} = this.state;
      //console.log(contestDetailsId,contestId,buyIn);
      const payload = {
        'buyIn':buyIn,
        'contestDetailsId': contestDetailsId ,
        'contestId':contestId,
        'gameType':'quiz',
        'skinId':"aio"
      };

      axios.post(apiBaseUrl,payload).then(
          function(response) {
              console.info(response);
                  if (response.status === 200) {
                    var url = process.env.REACT_APP_WEBD_BASE_URL + '/launch';
                    var s1  = '?wagerId=' + response.data.wagerId;
                    var s2  = '&skinId=' + process.env.REACT_APP_SKIN_ID;
                    var s3  = '&gameType=' + response.data.gameType;
                    var s4  = '&contestDetailsId='+response.data.contestDetailsId;
                    window.location.href = url + s1 + s2 + s3 + s4;
              } 
            }
      ).catch(function(error) {
          console.log(error);
      });
    }

    render() {
        const {balance, showContest, showBuyinModal, contestTitle,prizePool,buyIn,nPlaying,endAt} = this.state;

        if (!isLoggedIn()) {
            return <Redirect to="/login"/>
        }

        return (
          <div>
            { showContest===false ? (
          <div id="app-lobby-201">
            <div className="lobby201-content">
              <div className="header-container">
                <div className="header">
                  <div className="arrow-back-bg common201-bg" onClick={this.goBack}></div>
                  <div className="title">Quiz</div>
                </div>
                <div className="tabs">
                <Tabs>
                    <Tab label="Live">
                      <div className="cards201">
                        {quizBuyin.map((val,key) => (
                          <div className="card201-items club" key={key}>
                          <Item ContestDetails={val} onItemClick={this.onItemClick} />
                        </div>))}
                      </div>
                    </Tab>
                    <Tab label="My Matches">
                    <div className="cards201">
                          <div className="card201-items club">
                          {/* <Item /> */}
                          </div>
                      </div>
                    </Tab>                
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
            ):(<div>
            { showContest  &&
              (<QuizContest goBack={this.goBackContest} Title={contestTitle} buyin={buyIn}
                            prizePool={prizePool}
                            winners={buyIn}
                            numPlaying={nPlaying} 
                            endAt={endAt}
                            playHandler={this.onContestItemClick}
                            showBuyinModal={showBuyinModal}
                            onPlayBuyin={this.onPlayBuyin}
                            onCancelBuyin={this.onCancelBuyin}
                           />)}
                      </div>)}
          </div>
        );
    }
}

class Tabs extends React.Component{
    state ={
      activeTab: this.props.children[0].props.label
    }
    changeTab = (tab) => {
  
      this.setState({ activeTab: tab });
    };
    render(){
      
      let content;
      let buttons = [];
      return (
        <div>
          {React.Children.map(this.props.children, child =>{
            buttons.push(child.props.label)
            if (child.props.label === this.state.activeTab) content = child.props.children
          })}
           
          <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab}/>
          <div className="tab-content">{content}</div>
          
        </div>
      );
    }
  }
  
  const TabButtons = ({buttons, changeTab, activeTab}) =>{
     
    return(
      <div className="tab-buttons">
      {buttons.map(button =>{
         return <button key={button} className={button === activeTab? 'active': 'inactive'} onClick={()=>changeTab(button)}>{button}</button>
      })}
      </div>
    )
  }
  
  const Tab = props =>{
    return(
      <React.Fragment>
        {props.children}
      </React.Fragment>
    )
  }