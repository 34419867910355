import React, { Component } from 'react';
import store from 'store';
import {Redirect } from 'react-router-dom';
import isLoggedIn from './helpers/is_logged_in';
import axios from 'axios';

import { FaPlus,FaWallet} from 'react-icons/fa';
import { IconContext } from "react-icons";

import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'

export default class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: true,
            category:null,
            balance: null,
            earnings: 673,
            tables: {},
            quiztbls:[]
        };
    }

    
    fetchTables(gameType) {
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';
      var self = this;
      const {tables} = {...this.state};
      const tbls = tables;

      axios.get(apiBaseUrl + '/tables?gameType=' + gameType).then(
          function(response) {
              console.info(response);
              if (response.status === 200) {
                tbls[gameType] = response.data;
                self.setState({tables: tbls});
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }

    fetchLudoTables(gameType,tableType) {
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';
      var self = this;
      const {tables} = {...this.state};
      const tbls = tables;

      axios.get(apiBaseUrl + `/tables?gameType=${gameType}&tableType=${tableType}` ).then(
          function(response) {
              console.info(response);
              if (response.status === 200) {
                tbls[gameType] = response.data;
                self.setState({tables: tbls});
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }


    fetchquizTables() {
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL;
      var self = this;
      const {tables} = {...this.state};
      const tbls = tables;

      axios.get(apiBaseUrl+'/app/quiz/contests/').then(
          function(response) {
            console.info(response.data);
           
            if (response.status === 200) {
             var quizBuyin=[];

              if(quizBuyin.length <=0)
              {
                  for (var i=0; i < response.data.length; i++) {
                  quizBuyin.push(response.data[i]);
                }
              }

              self.setState({quiztbls: quizBuyin});
            }
        }
    ).catch(function(error) {
        console.log(error);
    });
  }

    fetchUserProfile() {
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';
      var self = this;

      axios.get(apiBaseUrl + '/balance').then(
          function(response) {
            console.log(response);
              if (response.status === 200 && Number.isFinite(response.data.balance)) {
                self.setState({balance: response.data.balance});
              } else {
                self.handleLogout();
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }

    componentDidMount() {
        this.fetchUserProfile();
        this.fetchTables('rummy');
        this.fetchTables('callbreak');
        this.fetchTables('chatai');
        // this.fetchTables('ludo');
        this.fetchLudoTables('ludo','classic');
        this.fetchLudoTables('ludo','ninja');
        this.fetchTables('snakesladders')
        this.fetchTables('teenpatti');
        this.fetchTables('bingo');
        // this.fetchquizTables();
    }

    handleLogout() {
        var {history} = this.props;
        store.remove('loggedIn');
        history.push('/login');
    }

    handleWallet(){
      var {history} = this.props;
      history.push('/MyWallet');
    }

    handleFantasy(){
      var {history} = this.props;
      history.push('/fantasy');
    }

    handleSlots(){
      var {history} = this.props;
      history.push('/slots');
    }

    goTobyType(gameType){
      var {history} = this.props;
      history.push(`/${gameType}`);
    }

    handleQuiz(){
      var {history} = this.props;
      var {isLoggedIn, balance, earnings,quiztbls} = this.state;

      console.log(quiztbls);
      
      history.push({
        pathname: '/quiz',
        state: {isLoggedIn, balance, earnings,quiztbls}
      });
    }

    plyerInfo()
    {
      var {history} = this.props;

      history.push('/PlayerInfo');
    }

    goTo(event, gameType, category) {
      var {history} = this.props;
      var {isLoggedIn, balance, earnings} = this.state;
      console.log(category);
      var tables = this.state.tables[gameType].filter(t => t.category === category);

      history.push({
        pathname: '/' + gameType + '-' + category,
        state: {isLoggedIn, balance, earnings, tables, gameType, category}
      });
    }

    handlePoker() {
        const url = process.env.REACT_APP_WEBD_BASE_URL +'/app/launchLobby?gameType=glpoker';

        const options = {
          headers: {
            "Authorization": "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImlkIjoxOTYsInVzZXJuYW1lIjoieWEzMSIsInNpdGUtaWQiOjEsInV1aWQiOiIwNzY4NWNjMC0zNmE3LTQxZDYtYTYyYy03MDdjMDhmNzhiNzkifSwiZXhwIjoxNjY1MDQxNzY2fQ.FPhYN8isUUyMpDlYaDQYKwzLpe07qgiKlXwrN3S0bdk",
            "X-Application": "itotqcfyffc7"
          }
        };

        fetch(url, null)
          .then( res => res.json() )
          // .then( data =>window.location.replace(data.url));
    }

    handleRwitc() {
      const url = process.env.REACT_APP_WEBD_BASE_URL+ '/app/launchLobby2?gameType=rwitc';

      const options = {
        headers: {
          "Authorization": "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImlkIjoxMTksInVzZXJuYW1lIjoiYWduZXMiLCJzaXRlLWlkIjo1LCJ1dWlkIjoiZDhhMWUxMzEtNTg2Yi00MjYyLTg5MGMtODJmZWQ4M2M1OTU1In0sImV4cCI6MTY0NTg4MjA2Nn0.aoGyM8aABKL1hbNmOps1jIzm1mB6qAE1cEv7JbNz3b0",
          "X-Application": "cqknjvlljhs9"
        }
      };

      fetch(url, null)
        .then( res => res.json() )
        .then( data =>window.location.replace(data.url));
  }

    render() {

        const {balance,earnings, tables,category} = this.state;
        
        console.log(category);

        if (!isLoggedIn()) {
            return <Redirect to="/login"/>
        }

        window.addEventListener("goToLobby", (event) => {
          console.log("goToLobby");
          // return <Redirect to="/dashboard"/>
        });

        var bal = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "INR",
          }).format(balance);

        return (
          <div id="app-lobby">
            <div className="main-content">
              <div className="header-container">
                <div className="header">
                  <div className="user-bg"
                      onClick={(event) => this.plyerInfo()}>
                   {/* <img src="./img/logo.png"/> */}
                  </div>
                  
                  <div className="cash-left" onClick={(event) => this.handleWallet()}>
                  <div>
                    <IconContext.Provider
                      value={{ color: 'white', size: '15px' }}
                              >
                        <div>
                        <FaWallet/>
                    </div>
                  </IconContext.Provider>
                    </div>
                    <div><p>&nbsp;&nbsp;&nbsp;</p></div>
                  <div>
                    <p>
                      <span>{bal}</span>
                    </p>
                    </div>

                    <p>&nbsp;&nbsp;</p>
                    <div>
                    <IconContext.Provider
                      value={{ color: 'white', size: '15px' }}
                              >
                        <div>
                        <FaPlus />
                    </div>
                  </IconContext.Provider>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fav-cards">
                <p className="fav-title">Featured Games</p>
                <div className="card-items">
                  <div className="Featured-games" ><span><img src="./img/Featured Games/1.png" onClick={(event) => this.handleFantasy()}/></span></div>
                  <div className="Featured-games" ><span><img src="./img/Featured Games/2.png" onClick={(event) => this.handlePoker()}/></span></div>
                  <div className="Featured-games" ><span><img src="./img/Featured Games/3.png" onClick={(event) => this.goTo(event, 'callbreak', 'bestof')}/></span></div>
                </div>
              </div>
              <div className="fav-cards">
                <p className='fav-title'>All Games</p>
              <div className="lobby-cards">
                <div className="card-block">
                <div className="fantasy common-bg"
                         onClick={(event) => this.handleFantasy()}>
                           <div className="lobby-label">Fantasy Cricket</div>
                    </div>
                  <div className="rummy common-bg"
                       onClick={(event) => this.goTo(event, 'rummy', '101-pool')}>
                         <div className="lobby-label">Rummy</div>
                  </div>
                  </div>
                <div className="card-block">
                  <div className="callbreak common-bg"
                       onClick={(event) => this.goTo(event, 'callbreak', 'bestof')}>
                         <div className="lobby-label">Callbreak</div>
                  </div>
                  <div className="ludo common-bg"
                            onClick={(event) => this.goTo(event, 'ludo', 'ninja')}>
                              <div className="lobby-label">Ludo</div>
                    </div>
                  </div>
                <div className="card-block">
                  <div className="ludo common-bg"
                            onClick={(event) => this.goTo(event, 'ludo', 'classic')}>
                              <div className="lobby-label">Ludo Classic</div>
                    </div>
                    <div className="rwitc common-bg" 
                    onClick={(event) => this.handleRwitc()}>
                        <div className="lobby-label">RWITC</div>
                    </div>
                </div>
                  
                <div className="card-block"> 
                    <div className="bingo common-bg"
                            onClick={(event) => this.goTo(event, 'bingo', 'skill')}>
                              <div className="lobby-label">Bingo</div>
                    </div>
                    <div className="poker common-bg"
                         onClick={(event) => this.handlePoker()}>
                           <div className="lobby-label">Poker</div>
                    </div>
                </div>

                <div className="card-block"> 
                    <div className="quiz common-bg"
                            onClick={(event) => this.handleQuiz()}>
                              <div className="lobby-label">Quiz</div>
                    </div>
                    
                    <div className="slot common-bg"
                         onClick={(event) => this.handleSlots()}>
                           <div className="lobby-label">Slots</div>
                    </div>
                </div>

                <div className="card-block"> 
                    <div className="teenPatti common-bg"
                          onClick={(event) => this.goTo(event, 'teenpatti', 'points')}>
                    </div>
                    <div className="snakes common-bg"
                          onClick={(event) => this.goTo(event, 'snakesladders','brainy')}>
                    </div>
                </div>
                <div className="card-block"> 
                    <div className="abahar common-bg"
                          onClick={(event) => this.goTobyType('abahar')}>
                    </div>
                    <div className="chatai common-bg"
                          onClick={(event) => this.goTo(event, 'chatai', 'bestof')}>
                    </div>
                </div>
                <div className="card-block"> 
                    <div className="dtiger common-bg"
                          onClick={(event) => this.goTobyType('dt')}>
                    </div>
                    <div className="seven common-bg"
                          onClick={(event) => this.goTobyType('seven')}>
                    </div>
                </div>
                <div className="card-block"> 
                    <div className="jm common-bg"
                          onClick={(event) => this.goTobyType('jm')}>
                    </div>
                </div>
                  <BottomNavBar/>
              </div>
            </div>
          </div>
          </div>
        );
    }
}


  const BottomNavBar = props => {
  const history = useHistory()
  const [activeTabs, setActiveTabs] = useState(props.name)

  useEffect(() => {
      switch (activeTabs) {
          case 'mymatches':
              history.push('/dashboard')
              break;
          case 'dashboard':
              history.push('/dashboard')
              break;
          case 'wallet':
              history.push('/MyWallet')
              break;
          case 'refer':
              history.push('/dashboard')
              break;
          default:
              history.push('/dashboard')
              setActiveTabs('dashboard')
              break;
      }
  }, [activeTabs, history])
  
  return (
    <div className='bottom-nav'>
        <div className='bn-tab'>
            {activeTabs === 'mymatches' ?
                <div className='Lobby-layout-24' onClick={() => setActiveTabs('mymatches')}><p>My&nbsp;Matches</p></div>:
                <div className='Lobby-layout-28' onClick={() => setActiveTabs('mymatches')}><p>My&nbsp;Matches</p></div> }
        </div>
        <div className='bn-tab'>
            {activeTabs === 'dashboard' ?
                <div className='Lobby-layout-25' onClick={() => setActiveTabs('dashboard')}><p>Play&nbsp;{"&"}&nbsp;Win</p></div> :
                <div className='Lobby-layout-29' onClick={() => setActiveTabs('dashboard')}><p>Play&nbsp;{"&"}&nbsp;Win</p></div>}
        </div>
        <div className='bn-tab'>
            {activeTabs === 'wallet' ?
                <div className='Lobby-layout-26' onClick={() => setActiveTabs('wallet')}><p>{"Wallet"}</p></div> :
                <div className='Lobby-layout-30' onClick={() => setActiveTabs('wallet')}><p>{"Wallet"}</p></div>}
        </div>
        <div className='bn-tab'>
            {activeTabs === 'refer' ?
                <div className='Lobby-layout-27' onClick={() => setActiveTabs('refer')}><p>Refer&nbsp;{"&"}&nbsp;Earn</p></div> :
                <div className='Lobby-layout-31' onClick={() => setActiveTabs('refer')}><p>Refer&nbsp;{"&"}&nbsp;Earn</p></div>}
        </div>
    </div>
  )
}