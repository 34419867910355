import React, { Component } from 'react';
import store from 'store';
import {Redirect } from 'react-router-dom';
import isLoggedIn from '../helpers/is_logged_in';
import capitalize from '../helpers/capitalize';
import axios from 'axios';
import BuyinModal from '../BuyinModal';

var bingoBuyin=[];

/* Table Card Item */
function Item(props) {
  const {band, buyin, onItemClick} = props;
  const icon = `./img/${capitalize(band)}.png`;

  return (
    <div className="item" onClick={(event) => onItemClick(event, buyin)}>
       <p>
        <span>₹{buyin}</span>
          <div className="play">
            <button type="button" aria-label="addcash">Play</button>
        </div>
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <div className="line-border"></div>
      <div className="sub-item">
        <p>
          <span>1st Prize ₹{buyin*2-((buyin*2)*(0.15))}</span>
          <span>Players 2</span></p>
      </div>
    </div>
  );
}

export default class SnakesLadder extends Component {
    constructor(props) {
        super(props);

        this.state = {
          ...props.location.state,

          showBuyinModal: false,

          /* Data needed to launch the buyin */
          players: 2,
          buyIn: null,
          
        }
    }

    goBack = (event) => {
      var {history} = this.props;
      history.goBack();
    }

    handleLogout() {
        var {history} = this.props;

        store.remove('loggedIn');
        history.push('/login');
    }

    fetchTables(gameType) {
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';
      var self = this;
      const {tables} = {...this.state};
      const tbls = tables;

      axios.get(apiBaseUrl + '/tables?gameType=snakesladders').then(
          function(response) {
              console.info(response.data);
              if(bingoBuyin.length <=0)
              {

                for (var i=0; i < response.data.length; i++) {
                bingoBuyin.push(response.data[i].betValue)
                }
              }
              console.log(bingoBuyin); 
              if (response.status === 200) {
                tbls[gameType] = response.data
                self.setState({tables: tbls});
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }

    componentDidMount() {
        this.fetchTables('snakesladder');
    }

    onItemClick = (event, buyin) => {
      if (!buyin)
        return;

      const {players, tables} = this.state;

      var tbl = null;
      for (var i = 0; i < tables.length; ++i) {
        var table = tables[i];
       
        if (table.numPlayers === players &&
            table.minBuyin <= buyin && table.maxBuyin >= buyin) {     
          tbl = table;
          break;
        }
      }

      if (tbl) {
        console.info("table selected", table);
        this.setState({buyIn: buyin, tableId: tbl.tableId, showBuyinModal: true});
      }
    }

    onCancelBuyin = (_event) => {
      this.setState({showBuyinModal: false});
    }

    onPlayBuyin = (event) => {
      event.preventDefault();

      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/app';

      const {buyIn, tableId, gameType} = this.state;
      
      console.log(buyIn, tableId, gameType);

      const payload = {
          'buyIn': buyIn,
          'tableId': tableId,
          'gameType': gameType,
          'skinId': "aio"
      };

      axios.post(apiBaseUrl + '/buyin2', payload).then(
        function(response) {
          console.info(response);
          if (response.status === 200) {
            var url = response.data.url ;
            var searchParams = new URLSearchParams(new URL(url).search);
            searchParams.set("skinId", "aio");
            var updatedURL = url.split('?')[0] + '?' + searchParams.toString();
            console.log(updatedURL);

            window.location.href = updatedURL;
        }
    }
      ).catch(function(error) {
          console.log(error);
      });
    }

    toggleNumPlayers = (event, numPlayers) => {
      this.setState({players: numPlayers});
    }

    render() {
        const {balance, showBuyinModal, players, buyIn, category} = this.state;

        if (!isLoggedIn()) {
            return <Redirect to="/login"/>
        }

        console.log(bingoBuyin);

        var bal = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "INR",
          }).format(balance);

        //   bingoBuyin.reverse();

        return (
          <div id="app-lobby-201">
            <div className="lobby201-content">
              <div className="header-container">
                <div className="header">
                  <div className="arrow-back-bg common201-bg" onClick={this.goBack}></div>
                  <div className="title">Snakes Ladder</div>
                </div>
              </div>
              <div className="cards201">
              {bingoBuyin.map((val,key) => (
                  <div className="card201-items club" key={key}>
                    <Item buyin={val} onItemClick={this.onItemClick} />
                    </div>
                ))}             
              </div>
            </div>
            { showBuyinModal &&
              <BuyinModal category={category} players={players} buyin={buyIn}
                          cancelHandler={this.onCancelBuyin}
                          playHandler={this.onPlayBuyin}/>
            }
          </div>
        );
    }
}