import React, { Component } from 'react';
import store from 'store';
import {Redirect } from 'react-router-dom';
import isLoggedIn from '../helpers/is_logged_in';

export default class Slots extends Component {
    constructor(props) {
        super(props);

        this.state = {
          ...props.location.state,
        }
    }

    goBack = (event) => {
      var {history} = this.props;
      history.goBack();
    }

    handleLogout() {
        var {history} = this.props;

        store.remove('loggedIn');
        history.push('/login');
    }

    render() {
        const {balance, showBuyinModal, players, buyIn, category} = this.state;

        if (!isLoggedIn()) {
            return <Redirect to="/login"/>
        }

        var bal = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "INR",
          }).format(balance);

        return (
          <div id="app-lobby-201">
            <div className="lobby201-content">
              <div className="header-container">
                <div className="header">
                  <div className="arrow-back-bg common201-bg" onClick={this.goBack}></div>
                  <div className="title">Slots</div>
                </div>
              </div>
              <div className="lobby-cards">
                <div className="card-block1">
                  <div className="slot1 common-bg">
                    <div className="lobby-label" onClick={()=> window.open("https://csn.peka.ooo/launch?gameId=912&bankId=234", "_self")}>Lucky Jester</div>
                  </div>
                  <div className="slot2 common-bg" onClick={()=> window.open("https://csn.peka.ooo/launch?gameId=900&bankId=234", "_self")}>
                         <div className="lobby-label">Save the Egg</div>
                  </div>
                  </div>
                <div className="card-block1">
                  <div className="slot3 common-bg" onClick={()=> window.open("https://csn.peka.ooo/launch?gameId=916&bankId=234", "_self")}>
                        <div className="lobby-label1">Leprechaun's Prosperity</div>
                  </div>
                  <div className="slot4 common-bg" onClick={()=> window.open("https://csn.peka.ooo/launch?gameId=914&bankId=234", "_self")}>
                        <div className="lobby-label">Lucky Lunar Year</div>
                    </div>
                  </div>
                  
                <div className="card-block1"> 
                    <div className="slot5 common-bg" onClick={()=> window.open("https://csn.peka.ooo/launch?gameId=913&bankId=234", "_self")}>
                        <div className="lobby-label">Santa Robot</div>
                    </div>
                    <div className="slot6 common-bg" onClick={()=> window.open("https://csn.peka.ooo/launch?gameId=906&bankId=234", "_self")}>
                        <div className="lobby-label">Spell the Magic</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}


