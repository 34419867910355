import React, { Component } from 'react';
import store from 'store';
import {Redirect } from 'react-router-dom';
import isLoggedIn from './helpers/is_logged_in';
import axios from 'axios';

export default class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
          isLoggedIn: true,
          username: null,
          earnings: 673,
          tables: {}
      };
    }

    goBack = (event) => {
      var {history} = this.props;
      history.goBack();
    }

    fetchUserProfile() {
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';
      var self = this;
      const {tables} = {...this.state};
      const tbls = tables;

      axios.get(apiBaseUrl + '/balance').then(
          function(response) {
              console.info(response.data.username);

              if (response.status === 200 && Number.isFinite(response.data.balance)) {
                self.setState({username: response.data.username});
              } else {
                self.handleLogout();
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }

    componentDidMount() {
        this.fetchUserProfile();
    }

    handleLogout() {
        var {history} = this.props;

        store.remove('loggedIn');
        history.push('/login');
    }
    
    render() {
        const {username, earnings} = this.state;

        if (!isLoggedIn()) {
            return <Redirect to="/login"/>
        }

        return (
          <div id="app-lobby">
            <div className="main-content">
              <div className="wallet-container2">
                <div className="wallet-header">
                  <div className="arrow-back-bg1 common201-bg" onClick={this.goBack}></div>
                  <div className="title"> My Profile</div>
                </div>
                </div>
                <div className="player-profile">
                  <div className="player-pic"></div>
                  <div className="user-details"><p>Player Name-{username}</p></div>
                </div>
                <div className='playerinfo-container'>
                <button className='btn1 btn-1 btn-sep'>Edit Profile</button>
                <button className='btn'>FAQs</button>
                <button className='btn'>Contact Us</button>
                <button className='btn'>Privacy Policy</button>
                <button className='btn'>Terms of Service</button>
                <button className='btn'>How to Play</button>
                <button className='btn'>Special Contest</button>
                <button className='btn' onClick={(event) => this.handleLogout()}>Logout</button>
                </div>
              </div>
          </div>
        );
    }
}