import React, { Component } from 'react';
import store from 'store';
import {Redirect } from 'react-router-dom';
import isLoggedIn from './helpers/is_logged_in';
import axios from 'axios';

import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'

export default class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
          isLoggedIn: true,
          balance: [],
          earnings: 673,
          tables: {}
      };
    }

    goBack = (event) => {
      var {history} = this.props;
      history.goBack();
    }

    fetchUserProfile() {
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';
      var self = this;
      const {tables} = {...this.state};
      const tbls = tables;
      
      axios.get(apiBaseUrl + '/balance').then(
          function(response) {
              if (response.status === 200 && Number.isFinite(response.data.balance)) {
                self.setState({balance: response.data});
              } else {
                self.handleLogout();
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }

    handleLogout() {
        var {history} = this.props;
        store.remove('loggedIn');
        history.push('/login');
    }

    componentDidMount() {
      this.fetchUserProfile();
    }

    goTo(event, gameType, category) {
      var {history} = this.props;
      var {isLoggedIn, balance, earnings} = this.state;
      var tables = this.state.tables[gameType].filter(t => t.category === category);

      history.push({
        pathname: '/' + gameType + '-' + category,
        state: {isLoggedIn, balance, earnings, tables, gameType, category}
      });
    }

    render() {
        const {balance, earnings} = this.state;

        if (!isLoggedIn()) {
            return <Redirect to="/login"/>
        }

        // var bal = Intl.NumberFormat("en-US", {
        //     style: "currency",
        //     currency: "INR",
        //   }).format(balance);

        return (
          <div id="app-lobby">
            <div className="main-content">
              <div className="wallet-container2">
                <div className="wallet-header">
                  <div className="arrow-back-bg1 common201-bg" onClick={this.goBack}></div>
                  <div className="title"> My Wallet</div>
                </div>
                <p>
                </p>
                </div>
                <div className="wallet-container">
                <div className="wallet-header1">
                  <div className="title"> Total Cash Balance</div>
                  <p>₹ {balance.balance}</p>
                  <div className="add-cash">
                    <button type="button" aria-label="addcash" onClick={(event) => this.handleWallet()}>Add cash</button>
                  </div>
                </div>
                </div>
                <p>
                </p>              
                <div className="wallet-container1">
                <div className="wallet-header">
                <div className='wallet-title'>
                  <div className="title"> Winning Cash</div>
                  <p>₹ {balance.cashWinnings}</p>
                  </div>
                  
                  <div className="add-cash">
                    <button type="button" aria-label="addcash" onClick={(event) => this.handleWallet()}>Withdraw</button>
                  </div>
                </div>
                </div>

                <p>
                </p>
                <div className="wallet-container1">
                <div className="wallet-header">
                  <div className='wallet-title'>
                  <div className="title">Deposit Cash</div>
                  <p>₹ {balance.cash}</p>
                  </div>
                  <div className="add-cash">
                    <button type="button" aria-label="addcash" onClick={(event) => this.handleWallet()}>Add Cash</button>
                  </div>
                </div>
                </div>

                <p>
                </p>
                <div className="wallet-container1">
                <div className="wallet-header">
                <div className='wallet-title'>
                  <div className="title">Bonus Earned</div>
                  <p>₹ {balance.bonus}</p>
                  </div>
                  <div className="add-cash">
                    <button type="button" aria-label="addcash" onClick={(event) => this.handleWallet()}>Get More</button>
                  </div>
                  </div>
                </div>
              </div>
            <BottomNavBar/>
          </div>
        );
    }
}

const BottomNavBar = props => {
  const history = useHistory()
  const [activeTabs, setActiveTabs] = useState(props.name)

  useEffect(() => {
      switch (activeTabs) {
          case 'mymatches':
            history.push('/dashboard')
              break;
          case 'dashboard':
              history.push('/dashboard')
              break;
          case 'wallet':
              history.push('/MyWallet')
              break;
          case 'refer':
            history.push('/dashboard')
              break;
      }
  }, [activeTabs, history])
  
  return (
    <div className='bottom-nav'>
      <div className='bn-tab'>
        {activeTabs === 'mymatches' ?
            <div className='Lobby-layout-24' onClick={() => setActiveTabs('mymatches')}><p>My&nbsp;Matches</p></div>:
            <div className='Lobby-layout-28' onClick={() => setActiveTabs('mymatches')}><p>My&nbsp;Matches</p></div> }
    </div>
    <div className='bn-tab'>
        {activeTabs === 'dashboard' ?
            <div className='Lobby-layout-25' onClick={() => setActiveTabs('dashboard')}><p>Play&nbsp;{"&"}&nbsp;Win</p></div> :
            <div className='Lobby-layout-29' onClick={() => setActiveTabs('dashboard')}><p>Play&nbsp;{"&"}&nbsp;Win</p></div>}
    </div>
    <div className='bn-tab'>
        {activeTabs === 'wallet' ?
            <div className='Lobby-layout-26' onClick={() => setActiveTabs('wallet')}><p>{"Wallet"}</p></div> :
            <div className='Lobby-layout-26' onClick={() => setActiveTabs('wallet')}><p>{"Wallet"}</p></div>}
    </div>
    <div className='bn-tab'>
        {activeTabs === 'refer' ?
            <div className='Lobby-layout-27' onClick={() => setActiveTabs('refer')}><p>Refer&nbsp;{"&"}&nbsp;Earn</p></div> :
            <div className='Lobby-layout-31' onClick={() => setActiveTabs('refer')}><p>Refer&nbsp;{"&"}&nbsp;Earn</p></div>}
    </div>
</div>
)
}