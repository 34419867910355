import React, { Component } from 'react';
import store from 'store';
import {Redirect } from 'react-router-dom';
import isLoggedIn from '../helpers/is_logged_in';
import capitalize from '../helpers/capitalize';
import axios from 'axios';
import BuyinModal from '../BuyinModal';


export default class Rummy extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isLoggedIn: true,
        balance: null,
        earnings: 673,
        tables: {}
    };
    }

    goBack = (event) => {
      var {history} = this.props;
      history.goBack();
    }


    fetchTables(gameType) {
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';

      var self = this;
      const {tables} = {...this.state};
      const tbls = tables;

      axios.get(apiBaseUrl + '/tables?gameType=' + gameType).then(
          function(response) {
              console.info(response);
              if (response.status === 200) {
                tbls[gameType] = response.data
                self.setState({tables: tbls});
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }

    fetchUserProfile() {
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';

      var self = this;
      const {tables} = {...this.state};
      const tbls = tables;

      axios.get(apiBaseUrl + '/balance').then(
          function(response) {
              console.info(response);
              if (response.status === 200 && Number.isFinite(response.data.balance)) {
                self.setState({balance: response.data.balance});
              } else {
                self.handleLogout();
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }

    componentDidMount() {
        this.fetchUserProfile();
        this.fetchTables('rummy');
    }

    goTo(event, gameType, category) {
      var {history} = this.props;
      var {isLoggedIn, balance, earnings} = this.state;
      var tables = this.state.tables[gameType].filter(t => t.category === category);

      history.push({
        pathname: '/' + gameType + '-' + category,
        state: {isLoggedIn, balance, earnings, tables, gameType, category}
      });
    }

    render() {
        const {balance, showBuyinModal, players, buyIn, category} = this.state;

        if (!isLoggedIn()) {
            return <Redirect to="/login"/>
        }

        var bal = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "INR",
          }).format(balance);

        return (
          <div id="app-lobby-201">
            <div className="lobby201-content">
              <div className="header-container">
                <div className="header">
                  <div className="arrow-back-bg common201-bg" onClick={this.goBack}></div>
                  <div className="title">Rummy</div>
                  <div className="cash-left">
                    <div className="amount">
                      <p><span>{bal}</span></p>
                      <p>Cash Balance</p>
                    </div>
                    <div>
                      <button type="button" aria-label="addcash">
                        <img src="./img/plus.png" alt="plus"/>
                        </button>
                    </div>
                  </div>
                </div>
                <div className="additional-earnings">
                  <p>Online 1,25,000</p>
                </div>
              </div>
              <div className="lobby-cards">

                <div className="card-block">
                  <div className="pool-101 common-bg-rummy"
                       onClick={(event) => this.goTo(event, 'rummy', '101-pool')}>
                  </div>
                  <div className="pool-201 common-bg-rummy"
                       onClick={(event) => this.goTo(event, 'rummy', '201-pool')}>
                  </div>

                  <div className="points-rummy common-bg-rummy"
                       onClick={(event) => this.goTo(event, 'rummy', 'points')}>
                  </div>
                  <div className="deals-rummy common-bg-rummy"
                       onClick={(event) => this.goTo(event, 'rummy', 'bestof')}>
                  </div>
                </div>
               </div>
              
            </div>
          </div>
        );
    }
}