import React, { Component } from "react";
import {Redirect } from 'react-router-dom';

import FantasyL from "../FantasyApp";
import {
  accountDataReducer,
  initialState as accountData,
} from "../reducers/accountDataReducer";
import { AccountDataProvider } from "../context/accountData";

import "../styles/style.scss";

import isLoggedIn from '../helpers/is_logged_in';

export default class Fantasy extends Component{
  
  render() {

    console.log(accountData);
    // if (!isLoggedIn()) {
    //   return <Redirect to="/login"/>}
      return(
      <AccountDataProvider initialState={accountData} reducer={accountDataReducer}>
        <FantasyL />
      </AccountDataProvider>
    );
  }
}
