import React, { Component } from 'react';
import './App.css';
import {Route, Switch} from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import Pool201 from './Lobby/Pool201';
import Pool101 from './Lobby/Pool101';
import Points from './Lobby/Points';
import BestOf from './Lobby/BestOf';
import Callbreak from './Lobby/Callbreak';
import Ludo from './Lobby/ludo';
import Ludoclassic from './Lobby/ClassicLudo';
import SnakesLadder from './Lobby/Snakesladder'
import TeenPatti from './Lobby/TeenPatti';
import Bingo from './Lobby/Bingo';
import Rummy from "./Lobby/Rummy";
import PlayerInfo from "./PlayerInfo";
import MyWallet from "./Wallet";
import Registration from "./Registration";
import Fantasy from "./Lobby/Fantasy";
import Slots from "./Lobby/Slots"
import Quiz from "./Lobby/Quiz";
import Abahar from "./Lobby/Abahar";
import Chatai from "./Lobby/chatai";
import Dtiger from "./Lobby/Dtiger.js";
import Seven from "./Lobby/Seven";
import JMunda from 'Lobby/Jmunda';

const App = () => (
  <div className="app-routes">
    <Switch>
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/Rummy" component={Rummy}/>
      <Route path="/PlayerInfo" component={PlayerInfo}/>
      <Route path="/rummy-201-pool" component={Pool201} />
      <Route path="/rummy-101-pool" component={Pool101} />
      <Route path="/rummy-bestof" component={BestOf} />
      <Route path="/rummy-points" component={Points} />
      <Route path="/callbreak-bestof" component={Callbreak} />
      <Route path="/ludo-classic" component={Ludoclassic} />
      <Route path="/ludo-ninja" component={Ludo} />
      <Route path="/snakesladders-brainy" component={SnakesLadder} />
      <Route path="/teenpatti-points" component={TeenPatti} />
      <Route path="/slots" component={Slots}/>
      <Route path="/bingo-skill" component={Bingo} />
      <Route path="/abahar" component={Abahar} />
      <Route path="/chatai-bestof" component={Chatai} />
      <Route path="/dt" component={Dtiger} />
      <Route path="/seven" component={Seven} />
      <Route path="/jm" component={JMunda} />
      <Route path="/fantasy" component={Fantasy}/>
      <Route path="/quiz" component={Quiz}/>
      <Route path="/MyWallet" component={MyWallet} />
      <Route path="/Registration" component={Registration} />
      <Route path="/" component={Login} />
    </Switch>
  </div>
);

export default App;