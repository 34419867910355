import React, { Component } from 'react';
import axios from 'axios';
import store from 'store';

axios.defaults.withCredentials = true;

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: null,
            password: null,
            rememberMe: false
        };
    }
    
    handleLogin(event) {
        event.preventDefault();

        var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';

        var {history} = this.props;
        const {username, password} = this.state;
        const payload = {
            "username": username,
            "password": password,
        };

        axios.post(apiBaseUrl + '/login', payload).then(
            function(response) {
                console.info(response);
                if (response.status === 200) {
                    store.set('loggedIn', true);
                    history.push('/dashboard');
                } else if (response.status === 204) {
                    alert("Username, Password do not match");
                } else {
                    alert("Username does not exist");
                }
            }
        ).catch(function(error) {
            console.log(error);
        });
    }

    render() {
        return (
            <div id="app">
            <div className="main-wrapper">
          <form>
              <div className="main-title">
                  {/* <h1 className="">Josh Rummy</h1> */}
                  <img src="./img/Logo1.png"/>
              </div>
              <div className="form-group">
                <label htmlFor="email" className="new-title">Username</label>
                <input className="form-control" id="email"
                       onChange={(event) => this.setState({username:event.target.value})}/>
              </div>
              <div className="form-group">
                <label htmlFor="pwd" className="new-title">Password</label>
                <input type="password" className="form-control" id="pwd"
                       onChange={(event) => this.setState({password:event.target.value})}/>
              </div>
              <div className="new-content reset"><span className="new-title">Forgot password?</span><a>Reset</a>
              <p>&nbsp;&nbsp;&nbsp;</p>
              <button type="submit"  className="btn common-btn login-btn"
                      onClick={(event) => this.handleLogin(event)}>
                Login
              </button>
              </div>
              
              <div className="signed-in"><p>Keep me signed in</p>
                  <span className="toggle-container">
                  <label className="switch">
                      <input type="checkbox" checked
                             onChange={(event) => this.setState({rememberMe:event.target.value})}/>
                      <span className="slider round"></span>
                    </label>
              </span></div>
              <div className="new-content sub-title">
                  <div className="line-border"></div>
                  {/* <span className="new-title">New to Josh Rummy?</span> */}
              </div>
              <button type="button" className="btn common-btn login-register">Register</button>
          </form>
      </div>
        </div>
    );
    }
}