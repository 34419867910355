import React, { Component } from 'react';
import store from 'store';
import {Redirect } from 'react-router-dom';
import isLoggedIn from '../helpers/is_logged_in';
import capitalize from '../helpers/capitalize';
import axios from 'axios';
import BuyinModal from '../BuyinModal';

import { GiConsoleController} from 'react-icons/gi';
import { GrTrophy} from 'react-icons/gr';
import { IconContext } from "react-icons";

var rummyBuyin=[];
/* Table Card Item */
function Item(props) {
  const {band,points,buyin, minBuyin, maxBuyin,onItemClick} = props;
  const icon = `./img/${capitalize(band)}.png`;

  return (
    <div className="item" onClick={(event) => onItemClick(event, buyin)}>
      <p>
        <span>₹{buyin}</span>
          <div className="play">
            <button type="button" aria-label="addcash">Play</button>
        </div>
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <div className="line-border"></div>
      <div className="sub-item">
        <p>
          <span>
          <IconContext.Provider
            value={{color: 'grey', size: '10px'}}>
              <div>
                <GrTrophy/>
                &nbsp;&nbsp;MIN/MAX ₹{minBuyin}/{maxBuyin}
                </div>
            </IconContext.Provider>
          </span>
          <span>
          <IconContext.Provider
            value={{ color: 'grey', size: '15px' }}>
              <div>
                <GiConsoleController/>
                &nbsp;Players 6
                </div>
            </IconContext.Provider>
          </span>
          </p>
      </div>
    </div>
  );
}

function ToggleBlock(props) {
  const {numPlayers, toggleNumPlayers} = props;

  return (
    <div className="toggle-block">
      <div className="toggle-btns">
        <span>Sort by</span>
        <p>
        &nbsp;&nbsp;
        </p>
        <button type="button" className="toggle-2 inactive"
                onClick={(event) => toggleNumPlayers(event, 6)}>
          6
        </button>
      </div>
    </div>
  );
}

export default class Points extends Component {
    constructor(props) {
        super(props);

        this.state = {
          ...props.location.state,

          showBuyinModal: false,

          /* Data needed to launch the buyin - category is from props */
          players: 6,
          buyIn: null,
        }
    }

    goBack = (event) => {
      var {history} = this.props;
      history.push('/Dashboard');
    }

    handleLogout() {
        var {history} = this.props;

        store.remove('loggedIn');
        history.push('/login');
    }

    onItemClick = (event, buyin) => {
      var tbl = null;

      if (!buyin)
        return;

      const {category, players, tables} = this.state;

      for (var i = 0; i < tables.length; ++i) {
        var table = tables[i];
        if (table.category === category &&
            table.numPlayers === players &&
            buyin &&
            table.minBuyin <= buyin &&
            table.maxBuyin >= buyin) {
          tbl = table;
          break;
        }
      }

      if (tbl) {
        console.info("table selected", table);
        this.setState({buyIn: buyin, tableId: tbl.tableId, showBuyinModal: true});
      }
    }

    onCancelBuyin = (_event) => {
      this.setState({showBuyinModal: false});
    }

    onPlayBuyin = (event) => {
      event.preventDefault();

      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/app';

      const {buyIn, tableId, gameType} = this.state;
      const payload = {
          'buyIn': buyIn,
          'tableId': tableId,
          'gameType': gameType,
          'skinId': "aio"
      };

      axios.post(apiBaseUrl + '/buyin2', payload).then(
          function(response) {
            console.info(response);
            if (response.status === 200) {
              var url = response.data.url ;
              var searchParams = new URLSearchParams(new URL(url).search);
              searchParams.set("skinId", "aio");
              var updatedURL = url.split('?')[0] + '?' + searchParams.toString();
              console.log(updatedURL);

              window.location.href = updatedURL;
          }
      }
      ).catch(function(error) {
          console.log(error);
      });
    }

    toggleNumPlayers = (event, numPlayers) => {
      this.setState({players: numPlayers});
    }

    fetchTables(gameType) {
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';

      var self = this;
      const {tables} = {...this.state};
      const tbls = tables;

      axios.get(apiBaseUrl + '/tables?gameType=' + gameType).then(
          function(response) {
              console.info(response);
              if(rummyBuyin.length <=0)
              {
                for (var i=0; i < response.data.length; i++) {
                  if(response.data[i].category==="points")
                  {
                    rummyBuyin.push(response.data[i])
                  }
                  rummyBuyin.reverse();
                }
              }
              if (response.status === 200) {
                tbls[gameType] = response.data
                self.setState({tables: tbls});
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }


    fetchUserProfile() {
      var apiBaseUrl = process.env.REACT_APP_WEBD_BASE_URL + '/api';

      var self = this;
      const {tables} = {...this.state};
      const tbls = tables;

      axios.get(apiBaseUrl + '/balance').then(
          function(response) {
              console.info(response);
              if (response.status === 200 && Number.isFinite(response.data.balance)) {
                self.setState({balance: response.data.balance});
              } else {
                self.handleLogout();
              }
          }
      ).catch(function(error) {
          console.log(error);
      });
    }

    componentDidMount() {
        this.fetchUserProfile();
        this.fetchTables('rummy');
    }

    goTo(event, gameType, category) {
      var {history} = this.props;
      var {isLoggedIn, balance, earnings} = this.state;
      var tables = this.state.tables[gameType].filter(t => t.category === category);

      history.push({
        pathname: '/' + gameType + '-' + category,
        state: {isLoggedIn, balance, earnings, tables, gameType, category}
      });
    }

    render() {
        const {balance, showBuyinModal, players, buyIn, category} = this.state;

        if (!isLoggedIn()) {
            return <Redirect to="/login"/>
        }

        console.log(rummyBuyin);

        var bal = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "INR",
          }).format(balance);

         

        return (
          <div id="app-lobby-201">
            <div className="lobby201-content">
              <div className="header-container">
                <div className="header">
                  <div className="arrow-back-bg common201-bg" onClick={this.goBack}></div>
                  <div className="title">Points Rummy</div>
                </div>
              </div>
              <div className="lobby-cards">
                <div className="card-block">
                  <div className="pool-101 common-bg-rummy"
                       onClick={(event) => this.goTo(event, 'rummy', '101-pool')}>
                  </div>
                  <div className="pool-201 common-bg-rummy"
                       onClick={(event) => this.goTo(event, 'rummy', '201-pool')}>
                  </div>
                  <div className="points-rummy common-bg-rummy activetab"
                       onClick={(event) => this.goTo(event, 'rummy', 'points')}>
                  </div>
                  <div className="deals-rummy common-bg-rummy"
                       onClick={(event) => this.goTo(event, 'rummy', 'bestof')}>
                  </div>
                </div>
               </div>

              <ToggleBlock numPlayers={players} toggleNumPlayers={this.toggleNumPlayers}/>

              <div className="cards201">
              {rummyBuyin.map((val,key) => (
                  <div className="card201-items club" key={key}>
                    <Item buyin={val.maxBuyin} points={val.betValue}minBuyin={val.minBuyin} maxBuyin={val.maxBuyin} onItemClick={this.onItemClick} />
                    </div>
                ))}
              </div>
            </div>
            { showBuyinModal &&
              <BuyinModal category={category} players={players} buyin={buyIn}
                          cancelHandler={this.onCancelBuyin}
                          playHandler={this.onPlayBuyin}/>
            }
          </div>
        );
    }
}