import dayjs from "dayjs";
import humanizeDuration from "humanize-duration";

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      s: () => "s",
      d: () => "d",
      m: () => "m",
      h: () => "hrs",
    },
  },
});

export const getCountdownTime = (matchTimeStamp) => {
  const now = dayjs();
  let units = ["d", "h","m"];
  const matchTime = dayjs.unix(matchTimeStamp);
  const diff = matchTime.diff(now);
  const dayDiff = matchTime.diff(now, "day");
  const minuteDiff = matchTime.diff(now, "minute");

  if (dayDiff === 0) {
    units = ["h", "m","s"];
  }

  if (minuteDiff <= 60 && minuteDiff > -60) {
    units = ["m", "s"];
  }

  const duration = shortEnglishHumanizer(diff, {
    units,
    round: true,
    delimiter: " ",
    spacer: "",
  });
  return diff < 0 ? `${duration} ago` : duration;
};
